import { Rating } from "primereact/rating";
import styled from "styled-components";
import axios from "axios";
import EditReview from "../review/EditReview";
import { useState } from "react";
const StyledRating = styled(Rating)`
    .pi-star-fill {
        color: #facc39 !important;
        font-size: 13px;
    }
    .pi-star {
        color: rgba(255, 255, 255, 0.4);
        font-size: 13px;
    }
`;

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};
const Review = ({ review, onButtonPressDelete }) => {
    const userData = getUserData();
    const [stateReview, setStateReview] = useState(review);
    const handleButtonPressDelete = () => {
        // Apelăm funcția de callback pentru a trimite evenimentul către componenta parinte
        onButtonPressDelete();
    };

    const deleteReview = (reviewId) => {
        const url = `${process.env.REACT_APP_API_URL}/web/reviews/${reviewId}`;

        const config = {
            headers: {
                Authorization: `Bearer ${userData.token.split("|")[1]}`,
            },
        };

        axios
            .delete(url, config)
            .then((response) => {
                console.log(response.data); // Dacă serverul returnează un răspuns
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.data); // Dacă serverul returnează un mesaj de eroare
                } else if (error.request) {
                    console.log("Cererea către server nu a primit răspuns.");
                } else {
                    console.log(error.message);
                }
            });
    };

    const handleReviewUpdate = (orice) => {
        setStateReview(orice);
    };

    return (
        <div className="flex flex-row space-x-5" key={review.id}>
            {/* <img className="w-16 h-16 md:hidden" src="/profile/avatar.jpg" alt="" /> */}
            <img
                className="hidden w-16 h-16 md:!block md:!visible rounded-full"
                src={review?.user_avatar ? review?.user_avatar : "/profile/avatar.jpg"}
                alt=""
            />
            <div className="flex flex-col w-full p-3 rounded-lg bg-slate-100">
                <div className="flex flex-row items-center justify-between">
                    <span className="font-bold">{review.user_name}</span>
                    <div className="flex flex-row items-center space-x-3">
                        <StyledRating value={Math.floor(review.score)} readOnly stars={5} cancel={false} />
                        <div className="flex text-white items-center justify-center w-10 h-10 bg-[#384F95] rounded-md text-md font-bold">
                            {Math.floor(review.score)}
                        </div>
                    </div>
                </div>

                <p className="mt-2">{review.body}</p>
                {review.images.length !== 0 && (
                    <img
                        className="object-cover rounded-md w-52 h-36"
                        src={`${process.env.REACT_APP_STORAGE}/${review.images[0].url}`}
                        alt={review.images[0].title}
                    />
                )}
                <div className="w-full h-px my-2 rounded-full bg-slate-300" />
                <div className="flex flex-row justify-between">
                    <div className="flex items-center space-x-2">
                        <i className="text-[#6DB6FD] gmui gmui-calendar-check"></i>
                        <span>{review.created_at}</span>
                    </div>
                    <div className="flex">
                        {userData !== null && review.user_name === userData.user.name && (
                            <EditReview onUpdate={handleReviewUpdate} review={review} />
                        )}
                        {userData !== null && review.user_name === userData.user.name && (
                            <button
                                className="px-3 py-1 font-medium text-red-400"
                                onClick={() => {
                                    deleteReview(review.id);
                                    handleButtonPressDelete();
                                }}>
                                Șterge
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Review;
