import React, { useEffect, useState } from "react";
import { useGetExperiencesQuery } from "../services/smartsenseApi";

import "./style.css";

const getUserData = () => {
    const user = localStorage.getItem("userData");
    return user ? JSON.parse(user) : null;
};

const Footer = () => {
    const [current, setCurrent] = useState(window.location.pathname);
    const [userData, setUserData] = useState(getUserData());
    const [experiences, setExperiences] = useState(undefined);
    const { data: bestExperience, isFetching: bestExperienceIsFetching, error: bestExperienceError } = useGetExperiencesQuery();

    useEffect(() => {
        if (!bestExperienceIsFetching && !bestExperienceError) {
            setExperiences(bestExperience?.data.slice(-3));
        }
    }, [bestExperienceIsFetching]);

    return (
        <footer className="main-footer fl-wrap">
            <div className="footer-inner fl-wrap">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="footer-widget fl-wrap">
                                <div className="footer-logo">
                                    <a href="/">
                                        <img src={`${process.env.REACT_APP_STORAGE}/logo.png`} alt="Logo" />
                                    </a>
                                </div>
                                <div className="footer-contacts-widget fl-wrap">
                                    <p className="">
                                        Exploreză cele mai frumoase zone și experiente - găsiți câteva dintre cele mai bune sfaturi din
                                        jurul orașului de la partenerii și prietenii noștri.
                                    </p>
                                    <ul className="footer-contacts fl-wrap no-list-style">
                                        <li>
                                            <span>
                                                <i className="gmui gmui-envelope"></i> E-mail :
                                            </span>
                                            <a href="mailto:admin@smartsense.ro" target="_blank">
                                                admin@smartsense.ro
                                            </a>
                                        </li>
                                        <li>
                                            <span>
                                                <i className="gmui gmui-map-marker"></i> Adresă :
                                            </span>
                                            <a href="#" target="_blank">
                                                Str. 8 Martie, Nr. 29, Galați
                                            </a>
                                        </li>
                                        <li>
                                            <span>
                                                <i className="gmui gmui-phone"></i> Telefon :
                                            </span>
                                            <a href="te:+40 0236 407 030">+40 0236 407 030</a>
                                        </li>
                                    </ul>
                                    {/* <div className="footer-social">
                                        <span>Find us on: </span>
                                        <ul className="no-list-style">
                                            <li><a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#" target="_blank"><i className="fab fa-twitter"></i></a></li>
                                            <li><a href="#" target="_blank"><i className="fab fa-instagram"></i></a></li>
                                            <li><a href="#" target="_blank"><i className="fab fa-vk"></i></a></li>
                                            <li><a href="#" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-widget fl-wrap">
                                <h3>Ultimele experiențe adăugate</h3>
                                <div className="footer-widget-posts fl-wrap">
                                    <ul className="no-list-style">
                                        {experiences?.map(function (experience, i) {
                                            return (
                                                <li className="clearfix" key={i}>
                                                    <a href={"/experience/" + experience.id} className="widget-posts-img">
                                                        {experience.images[0]?.url ? (
                                                            <>
                                                                <img
                                                                    src={process.env.REACT_APP_STORAGE + experience.images[0]?.url}
                                                                    className="respimg"
                                                                    alt=""
                                                                />
                                                            </>
                                                        ) : (
                                                            <>
                                                                <img src="/default/default-image.png" className="respimg" alt="" />
                                                            </>
                                                        )}
                                                    </a>
                                                    <div className="widget-posts-descr">
                                                        <a href={"/experience/" + experience.id} title="">
                                                            {experience.title}
                                                        </a>
                                                        <span className="widget-posts-date">
                                                            <i className="gmui gmui-map-marker"></i>{" "}
                                                            {experience.location == "" ? "Nu a fost setata adresa" : experience.location}{" "}
                                                        </span>
                                                    </div>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                    <a href="/map" className="footer-link fs-6">
                                        Descoperă mai multe <i className="gmui gmui-long-arrow-alt-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="footer-widget fl-wrap ">
                                <h3>Link-uri utile</h3>
                                <ul className="footer-list fl-wrap">
                                    <li>
                                        <a href="/" className={current == "/" ? "act-link" : ""}>
                                            Acasă
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/map" className={current == "/map" ? "act-link" : ""}>
                                            Experiențe și Puncte de interes
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/sensors" className={current == "/sensors" ? "act-link" : ""}>
                                            Senzori
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/terms" className={current == "/terms" ? "act-link" : ""}>
                                            Termeni și Condiții
                                        </a>
                                    </li>
                                    <li>
                                        <a href="/privacy-policy" className={current == "/privacy-policy" ? "act-link" : ""}>
                                            Politica de Confidențialitate
                                        </a>
                                    </li>
                                </ul>

                                <h3>Disponibile in curand: </h3>

                                <a href="#" className=" down-btn color3-bg fl-wrap">
                                    <svg
                                        className="pull-left"
                                        stroke="white"
                                        fill="white"
                                        strokeWidth="0"
                                        viewBox="0 0 1024 1024"
                                        height="20px"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M747.4 535.7c-.4-68.2 30.5-119.6 92.9-157.5-34.9-50-87.7-77.5-157.3-82.8-65.9-5.2-138 38.4-164.4 38.4-27.9 0-91.7-36.6-141.9-36.6C273.1 298.8 163 379.8 163 544.6c0 48.7 8.9 99 26.7 150.8 23.8 68.2 109.6 235.3 199.1 232.6 46.8-1.1 79.9-33.2 140.8-33.2 59.1 0 89.7 33.2 141.9 33.2 90.3-1.3 167.9-153.2 190.5-221.6-121.1-57.1-114.6-167.2-114.6-170.7zm-105.1-305c50.7-60.2 46.1-115 44.6-134.7-44.8 2.6-96.6 30.5-126.1 64.8-32.5 36.8-51.6 82.3-47.5 133.6 48.4 3.7 92.6-21.2 129-63.7z"></path>
                                    </svg>
                                    Apple Store
                                </a>
                                <a href="#" className=" down-btn color3-bg fl-wrap">
                                    <svg
                                        className="pull-left"
                                        stroke="white"
                                        fill="white"
                                        strokeWidth="0"
                                        viewBox="0 0 1024 1024"
                                        height="20px"
                                        width="20px"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M270.1 741.7c0 23.4 19.1 42.5 42.6 42.5h48.7v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h85v120.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V784.1h48.7c23.5 0 42.6-19.1 42.6-42.5V346.4h-486v395.3zm357.1-600.1l44.9-65c2.6-3.8 2-8.9-1.5-11.4-3.5-2.4-8.5-1.2-11.1 2.6l-46.6 67.6c-30.7-12.1-64.9-18.8-100.8-18.8-35.9 0-70.1 6.7-100.8 18.8l-46.6-67.5c-2.6-3.8-7.6-5.1-11.1-2.6-3.5 2.4-4.1 7.4-1.5 11.4l44.9 65c-71.4 33.2-121.4 96.1-127.8 169.6h486c-6.6-73.6-56.7-136.5-128-169.7zM409.5 244.1a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm208.4 0a26.9 26.9 0 1 1 26.9-26.9 26.97 26.97 0 0 1-26.9 26.9zm223.4 100.7c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c.1-30.6-24.3-55.3-54.6-55.3zm-658.6 0c-30.2 0-54.6 24.8-54.6 55.4v216.4c0 30.5 24.5 55.4 54.6 55.4 30.2 0 54.6-24.8 54.6-55.4V400.1c0-30.6-24.5-55.3-54.6-55.3z"></path>
                                    </svg>
                                    Google Play
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bg" data-ran="4">
                    <span className="footer-bg-pin footer-bg-pin-vis"></span>
                </div>
                <div className="footer-wave">
                    <svg viewBox="0 0 100 25">
                        <path fill="#fff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z"></path>
                    </svg>
                </div>
            </div>
            {/* <div className="sub-footer fl-wrap">
                <div className="container">
                    <div className="copyright"> © Townhub 2019 . All rights reserved.</div>
                    <div className="lang-wrap">
                        <div className="show-lang"><span><i className="fal fa-globe-europe"></i><strong>En</strong></span><i className="fa fa-caret-down arrlan"></i></div>
                        <ul className="lang-tooltip lang-action no-list-style">
                            <li><a href="#" className="current-lan" data-lantext="En">English</a></li>
                            <li><a href="#" data-lantext="Fr">Français</a></li>
                            <li><a href="#" data-lantext="Es">Español</a></li>
                            <li><a href="#" data-lantext="De">Deutsch</a></li>
                        </ul>
                    </div>
                    <div className="subfooter-nav">
                        <ul className="no-list-style">
                            <li><a href="#">Terms of use</a></li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </footer>
    );
};

export default Footer;
