import React, { useRef, useState, useEffect } from "react";
import { useFormik } from "formik";
import Layout from "./Layout";
import { Rating } from "primereact/rating";
import { classNames } from "primereact/utils";
import { Button } from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { FileUpload } from "primereact/fileupload";

import { Toast } from "primereact/toast";
import axios from "axios";

const AddReview = ({ userData, experience, onButtonPress }) => {
    const toast = useRef(null);
    const inputRef = useRef(null);
    const [imagesPreview, setImagesPreview] = useState([]);
    const [imagesURLs, setImagesURLs] = useState([]);

    const handleButtonPress = () => {
        // Apelăm funcția de callback pentru a trimite evenimentul către componenta parinte
        onButtonPress();
    };

    useEffect(() => {
        if (imagesPreview.length < 1) return;
        const newImageUrls = [];
        imagesPreview.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
        setImagesURLs(newImageUrls);
    }, [imagesPreview]);

    const showSuccess = (detailValue) => {
        toast.current.show({ severity: "success", summary: "Informația a fost trimisă cu succes", detail: detailValue, life: 3000 });
    };

    const showError = (detailValue) => {
        toast.current.show({
            severity: "error",
            summary: "Informația nu a putut fi trimisă către server",
            detail: detailValue,
            life: 3000,
        });
    };

    const formik = useFormik({
        initialValues: {
            body: "",
            score: null,
            approved: true,
            experience_id: experience.id,
            user_id: 1,
            images_upload: [],
        },
        validate: (data) => {
            let errors = {};

            if (!data.body) {
                errors.body = "Comentariul este necesar.";
            }

            if (data.body.length >= 3000) {
                errors.body = "Ați depășit numărul maxim de 3000 de caractere.";
            }

            if (!data.score) {
                errors.score = "Notă este necesar.";
            }

            return errors;
        },
        onSubmit: (data) => {
            console.log(userData.user.id);

            axios({
                method: "post",
                url: process.env.REACT_APP_API_URL + "/web/reviews",
                headers: {
                    Authorization: "Bearer " + userData.token.split("|")[1],
                },
                data: {
                    ...data,
                    score: data.score,
                    user_id: userData.user.id,
                },
            })
                .then((response) => {
                    formik.resetForm();
                    showSuccess(response?.data?.message);
                })
                .catch((error) => {
                    if (error.response) {
                        showError(error.response.status);
                    } else if (error.request) {
                        showError(error.request);
                    } else {
                        showError(error.message);
                    }
                });
        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const [theImages, setTheImages] = useState(null);

    const customBase64Uploader = async (event) => {
        let images = event.files;
        let temp = [];

        Object.keys(images).forEach((image) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(images[image]);
            fileReader.onload = () => {
                temp.push({
                    title: images[image].name,
                    image: fileReader.result,
                });
            };
        });

        console.log(images);

        formik.handleChange({
            target: { name: "images_upload", value: temp },
        });
    };

    const removeImage = (_id) => {
        const _theImages = theImages;
        const _theImage = _theImages.findIndex((x) => x.id === _id);
        var __theImages = [...theImages];
        delete __theImages[_theImage];
        __theImages = __theImages.filter((x) => x);

        console.log(__theImages);
        setTheImages(__theImages);
    };

    return (
        <Layout title="Adaugă recenzie">
            {userData === null ? (
                "Trebuie sa fi logat"
            ) : (
                <>
                    <Toast className="mt-16" ref={toast} />
                    <form onSubmit={formik.handleSubmit} className="p-fluid">
                        <div className="field">
                            <label htmlFor="score" className={classNames({ "p-error": isFormFieldValid("score") })}>
                                Evaluare*
                            </label>
                            <span className="p-float-label">
                                <Rating
                                    cancel={false}
                                    id="score"
                                    name="score"
                                    value={formik.values.score}
                                    onChange={formik.handleChange}
                                    // autoFocus
                                    className={classNames({ "p-invalid": isFormFieldValid("score") })}
                                />
                            </span>

                            {getFormErrorMessage("score")}
                        </div>

                        <div className="mt-4 field">
                            <span className="p-float-label">
                                <InputTextarea
                                    id="body"
                                    name="body"
                                    value={formik.values.body}
                                    onChange={formik.handleChange}
                                    // autoFocus
                                    className={classNames({ "p-invalid": isFormFieldValid("body") })}
                                />
                                <label htmlFor="body" className={classNames({ "p-error": isFormFieldValid("body") })}>
                                    Comentariu*
                                </label>
                            </span>
                            {getFormErrorMessage("body")}
                        </div>

                        <div>
                            <div className="mt-4 field">
                                <label htmlFor="images_upload" className={classNames({ "p-error": isFormFieldValid("images_upload") })}>
                                    Imagine*
                                </label>
                                <div className="fuzone">
                                    <FileUpload
                                        ref={inputRef}
                                        customUpload
                                        uploadHandler={customBase64Uploader}
                                        auto
                                        id="images_upload"
                                        name="images_upload"
                                        multiple
                                        accept="image/*"
                                        emptyTemplate={
                                            <div className="fu-text">
                                                <span>
                                                    <i className="fa fal-images gmui gmui-images"></i> Drag-and-drop imagini aici
                                                </span>
                                            </div>
                                        }
                                    />
                                    {theImages?.length > 0 && (
                                        <div style={{ padding: "0 10px" }}>
                                            {theImages.map(function (object, i) {
                                                return (
                                                    <div className="float-left mb-6 mr-1" key={i} style={{ maxWidth: 250 }}>
                                                        <img
                                                            className="mt-6 mb-1"
                                                            style={{ display: "inline-block" }}
                                                            src={`${process.env.REACT_APP_STORAGE}/${object.url}`}
                                                            alt="Dynamic Image"
                                                        />
                                                        <span
                                                            onClick={() => removeImage(object.id)}
                                                            className="text-base color2-bg p-button p-component p-button-raised p-button-secondary p-button-text p-button-sm">
                                                            Elimina
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* <div className="mt-2">
                                {imagesURLs.map((imageSrc, index) => (
                                    <img className="object-cover w-full rounded-lg h-80" key={index} src={imageSrc} />
                                ))}
                            </div>
                            {imagesPreview.length !== 0 && (
                                <button
                                    // style={{ textAlign: "center", padding: "auto", border: "1px solid black" }}
                                    className="inline-flex justify-center px-4 py-2 mt-2 text-sm font-medium text-white bg-red-500 border border-transparent rounded-md hover:bg-red-400 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                                    type="button"
                                    onClick={resetImageField}>
                                    Sterge {imagesPreview.length === 1 ? "imaginea" : "imaginile"}
                                </button>
                            )} */}
                        </div>

                        <button
                            onClick={handleButtonPress}
                            type="submit"
                            className="mx-auto mt-4 mb-4 bg-[#384F95] text-white px-3 py-1.5 rounded-md font-semibold">
                            Adaugă recenzie
                        </button>
                    </form>
                </>
            )}
        </Layout>
    );
};

export default AddReview;
