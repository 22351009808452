import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import { Skeleton } from "primereact/skeleton";

import utils from "../../utils/utils";
import { getApiPointsOfInterestsOfExperience } from "../../services/apiServices";

function MiniMapExperience({ iconType, experience_id }) {
    // const redOptions = { color: "Royal Blue" };

    const [sumLat, setSumLat] = useState(0);
    const [sumLng, setSumLng] = useState(0);
    const [poi, setPoi] = useState([]);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            const response = await getApiPointsOfInterestsOfExperience(experience_id);
            setIsFetching(response.success === true && response.error === false ? false : true);
            if (response.success === true && response.error === false && response.data.length !== 0) {
                setPoi(response.data.data);
                let sumLatitude = 0;
                let sumLongitude = 0;

                response.data.data.forEach((poi) => {
                    sumLatitude += poi?.latitude;
                    sumLongitude += poi?.longitude;
                });

                // console.log(response.data);
                const lengthPointsOfInterest = response.data.data.length;
                const lat = sumLatitude / lengthPointsOfInterest;
                const lng = sumLongitude / lengthPointsOfInterest;

                // console.log(lengthPointsOfInterest, lat, lng);

                setSumLat(lat);
                setSumLng(lng);
            }
        };

        if (experience_id !== undefined) fetchData();
    }, [experience_id]);

    return (
        <div>
            {/* {isFetching ? (
                <Skeleton className="mb-4" width="10rem" height="1.25rem"></Skeleton>
            ) : (
                <h3 className="mb-4 font-normal text-gray-600 text-md">{pointsOfIntrest.length} puncte de interes</h3>
            )} */}
            {!isFetching ? (
                poi.length === 0 ? (
                    <MapContainer
                        center={[45.2412834, 27.9318981]}
                        zoom={12}
                        scrollWheelZoom={false}
                        style={{ height: "500px", width: "100%", zIndex: "0" }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                    </MapContainer>
                ) : (
                    <MapContainer center={[sumLat, sumLng]} zoom={12} scrollWheelZoom={false} style={{ height: "500px", width: "100%" }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {poi?.map((pointofinterest, index) => {
                            return (
                                <Marker
                                    key={index}
                                    position={[pointofinterest?.latitude, pointofinterest?.longitude]}
                                    icon={utils.changeColor()}>
                                    <Popup>
                                        <div className="clearfix listing-item">
                                            <article className="geodir-category-listing fl-wrap">
                                                <div className="geodir-category-content fl-wrap">
                                                    <div className="geodir-category-content-title fl-wrap">
                                                        <div className="geodir-category-content-title-item">
                                                            <h3 className="mt-2 title-sin_map">
                                                                <a href={`/pointofinterest/${pointofinterest?.id}`}>
                                                                    {pointofinterest?.title.length > 40
                                                                        ? `${pointofinterest?.title.substring(0, 40)}...`
                                                                        : pointofinterest?.title}
                                                                </a>
                                                            </h3>
                                                            {pointofinterest?.location && (
                                                                <div className="geodir-category-location fl-wrap">
                                                                    <a href="#1" className="map-item">
                                                                        <i className="gmui gmui-map-marker"></i> {pointofinterest?.location}
                                                                    </a>
                                                                </div>
                                                            )}
                                                            {pointofinterest?.address && (
                                                                <div className="geodir-category-location fl-wrap">
                                                                    <a href="#1" className="map-item">
                                                                        <i className="gmui gmui-map-marker"></i> {pointofinterest?.address}
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="geodir-category-text fl-wrap">
                                                        <p className="small-text">
                                                            {pointofinterest?.description.length > 200
                                                                ? `${pointofinterest?.description.substring(0, 200)}...`
                                                                : pointofinterest?.description}
                                                        </p>
                                                        {pointofinterest?.tags && (
                                                            <div className="facilities-list fl-wrap">
                                                                <div className="facilities-list-title">Tags : </div>
                                                                <ul className="no-list-style">
                                                                    {pointofinterest?.tags?.map((tag, index) => (
                                                                        <li
                                                                            className="tolt"
                                                                            data-microtip-position="top"
                                                                            key={index}
                                                                            data-tooltip={tag.tag}>
                                                                            <img
                                                                                className="tagicon"
                                                                                src={`${process.env.REACT_APP_STORAGE}/${tag.icon_url}`}
                                                                                alt="Tag Icon"
                                                                            />
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div className="geodir-category-footer fl-wrap">
                                                        {pointofinterest?.point_of_interest_category && (
                                                            <a className="listing-item-category-wrap">
                                                                <div className="listing-item-category red-bg">
                                                                    <img
                                                                        className="caticon"
                                                                        src={`${process.env.REACT_APP_STORAGE}/${pointofinterest?.point_of_interest_category.icon_url}`}
                                                                        alt="Category Icon"
                                                                    />
                                                                </div>
                                                                <span style={{ backgroundColor: pointofinterest?.color }}>
                                                                    {pointofinterest?.point_of_interest_category.title}
                                                                </span>
                                                            </a>
                                                        )}

                                                        <div className="geodir-opt-list">
                                                            <ul className="no-list-style">
                                                                {pointofinterest?.phone_number && (
                                                                    <li>
                                                                        <a
                                                                            href={"tel:" + pointofinterest?.phone_number}
                                                                            className="show_gcc">
                                                                            <i className="gmui gmui-phone"></i>
                                                                            <span className="geodir-opt-tooltip">Contactează</span>
                                                                        </a>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    </Popup>
                                </Marker>
                            );
                        })}
                    </MapContainer>
                )
            ) : (
                <Skeleton width="100%" height="500px"></Skeleton>
            )}
        </div>
    );
}

export default MiniMapExperience;
