import React, { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useSelector, useDispatch } from "react-redux";
import { boundsActions } from "../../slice/map/bounds-slice";
import { listActions } from "../../slice/map/list-slice";

import {
    useGetCategoriesExperienceQuery,
    useGetCategoriesPointOfIntrestQuery,
    useGetTagsExperienceQuery,
} from "../../services/smartsenseApi";
import axios from "axios";

function SearchFilters({ filterStatus }) {
    let [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();

    const keywordFilterRedux = useSelector((state) => state.list.keywordFilter);
    const [searchValue, setSearchValue] = useState("");

    const tagsIDsRedux = useSelector((state) => state.bounds.tagsIDs);
    const toggleStationsRedux = useSelector((state) => state.bounds.toggleShowStations);
    const togglePOIEXP = useSelector((state) => state.list.togglePOIExp);
    const [toggleClearFilter, setToggleClearFilter] = useState(false);

    const filterTagsRedux = useSelector((state) => state.list.filterTags);
    const filterCategoryExpRedux = useSelector((state) => state.list.filterCategoryExp);
    const filterCategoryPoiRedux = useSelector((state) => state.list.filterCategoryPoi);

    const [newTags, setNewTags] = useState([]);
    const [newTagsDefault, setNewTagsDefault] = useState([]);
    const [newCategoriesExperiences, setNewCategoriesExperiences] = useState([]);
    const [newCategoriesExperiencesDefault, setNewCategoriesExperiencesDefault] = useState([]);
    const [newCategoriesPointsOfInterest, setNewCategoriesPointsOfInterest] = useState([]);
    const [newCategoriesPointsOfInterestDefault, setNewCategoriesPointsOfInterestDefault] = useState([]);

    const { data: tags, isFetching: isFetchingTags, error: errorTags } = useGetTagsExperienceQuery();
    const {
        data: categoriesExperience,
        isFetching: isFetchingCategoriesExperience,
        error: errorCategoriesExperience,
    } = useGetCategoriesExperienceQuery();
    const {
        data: categoriesPointsOfInterest,
        isFetching: isFetchingCategoriesPointsOfInterest,
        error: errorCategoriesPointsOfInterest,
    } = useGetCategoriesPointOfIntrestQuery();

    useEffect(() => {
        if (keywordFilterRedux !== null && keywordFilterRedux !== "") {
            setSearchValue(keywordFilterRedux);

            // Folosim setTimeout pentru a amâna apelul lui filterByEndpoint cu 1000ms (1 secundă)
            const timer = setTimeout(() => {
                filterByEndpoint();
            }, 1000);

            // Curățăm timer-ul dacă componenta se demontează sau keywordFilterRedux se schimbă
            return () => clearTimeout(timer);
        }
    }, [keywordFilterRedux]);

    function closeModal() {
        setIsOpen(false);

        if (filterTagsRedux !== null) setNewTags(filterTagsRedux);
        else {
            setNewTags(newTagsDefault);
        }

        if (filterCategoryExpRedux !== null) setNewCategoriesExperiences(filterCategoryExpRedux);
        else {
            setNewCategoriesExperiences(newCategoriesExperiencesDefault);
        }

        if (filterCategoryPoiRedux !== null) setNewCategoriesPointsOfInterest(filterCategoryPoiRedux);
        else {
            setNewCategoriesPointsOfInterest(newCategoriesPointsOfInterestDefault);
        }
    }

    function openModal() {
        setIsOpen(true);
    }

    useEffect(() => {
        if (filterTagsRedux === null) {
            if (!isFetchingTags && !errorTags) {
                const tagss = tags?.data;
                let temp = [];
                Object.keys(tagss).forEach((tag) => {
                    tagsIDsRedux.length === 0
                        ? temp.push({
                              id: tagss[tag].id,
                              tag: tagss[tag].tag,
                              status: false,
                          })
                        : tagsIDsRedux.forEach((tagRedux) => {
                              if (tagss[tag].id === tagRedux) {
                                  temp.push({
                                      id: tagss[tag].id,
                                      tag: tagss[tag].tag,
                                      status: true,
                                  });
                              } else {
                                  temp.push({
                                      id: tagss[tag].id,
                                      tag: tagss[tag].tag,
                                      status: false,
                                  });
                              }
                          });
                });

                setNewTags(temp);
                setNewTagsDefault(temp);
            }
        } else {
            setNewTags(filterTagsRedux);
        }
    }, [isFetchingTags, filterTagsRedux]);

    useEffect(() => {
        if (filterCategoryExpRedux === null) {
            const categoriesExp = categoriesExperience?.data;
            if (!isFetchingCategoriesExperience && !errorCategoriesExperience) {
                let temp = [];
                Object.keys(categoriesExp).forEach((category) => {
                    temp.push({
                        id: categoriesExp[category].id,
                        category: categoriesExp[category].title,
                        status: false,
                    });
                });
                setNewCategoriesExperiences(temp);
                setNewCategoriesExperiencesDefault(temp);
            }
        } else {
            setNewCategoriesExperiences(filterCategoryExpRedux);
        }
    }, [isFetchingCategoriesExperience, filterCategoryExpRedux]);

    useEffect(() => {
        if (filterCategoryPoiRedux === null) {
            const categoriesPoi = categoriesPointsOfInterest?.data;
            if (!isFetchingCategoriesPointsOfInterest && !errorCategoriesPointsOfInterest) {
                let temp = [];
                Object.keys(categoriesPoi).forEach((category) => {
                    temp.push({
                        id: categoriesPoi[category].id,
                        category: categoriesPoi[category].title,
                        status: false,
                    });
                });
                setNewCategoriesPointsOfInterest(temp);
                setNewCategoriesPointsOfInterestDefault(temp);
            }
        } else {
            setNewCategoriesPointsOfInterest(filterCategoryPoiRedux);
        }
    }, [isFetchingCategoriesPointsOfInterest, filterCategoryPoiRedux]);

    useEffect(() => {
        if (filterStatus) {
            const timer = setTimeout(() => {
                dispatch(listActions.updateFilterTags(newTags));
                filterByEndpoint();
            }, 1000);
            return () => {
                // Asigură-te că anulezi timeout-ul înainte ca efectul să fie re-apelat sau componenta să fie dezmontată
                clearTimeout(timer);
            };
        }
    }, [filterStatus, newTags]);

    function toggleStatusTags(tag) {
        setNewTags((prevTags) => {
            const updatedTags = prevTags.map((tagg) => {
                if (tagg.tag === tag) {
                    return {
                        ...tagg,
                        status: !tagg.status,
                    };
                }
                return tagg;
            });
            return updatedTags;
        });
    }

    function toggleStatusCategories(category, arrayCategories, arrayType) {
        const updatedCategories = arrayCategories.map((cat) => {
            if (cat.category === category) {
                return {
                    ...cat,
                    status: !cat.status,
                };
            }
            return cat;
        });

        if (arrayType === "experience") {
            setNewCategoriesExperiences(updatedCategories);
        } else if (arrayType === "pointOfInterest") {
            setNewCategoriesPointsOfInterest(updatedCategories);
        }
    }

    const cleanFilter = () => {
        dispatch(listActions.updateKeywordFilter(""));
        setSearchValue("");
        setToggleClearFilter(false);
        const tagss = tags?.data;
        if (!isFetchingTags && !errorTags) {
            let temp = [];
            Object.keys(tagss).forEach((tag) => {
                temp.push({
                    id: tagss[tag].id,
                    tag: tagss[tag].tag,
                    status: false,
                });
            });
            setNewTags(temp);
            dispatch(listActions.updateFilterTags(temp));
        }

        const categoriesExp = categoriesExperience?.data;
        if (!isFetchingCategoriesExperience && !errorCategoriesExperience) {
            let temp = [];
            Object.keys(categoriesExp).forEach((category) => {
                temp.push({
                    id: categoriesExp[category].id,
                    category: categoriesExp[category].title,
                    status: false,
                });
            });
            setNewCategoriesExperiences(temp);
            dispatch(listActions.updatefilterCategoryExp(temp));
        }

        const categoriesPoi = categoriesPointsOfInterest?.data;
        if (!isFetchingCategoriesPointsOfInterest && !errorCategoriesPointsOfInterest) {
            let temp = [];
            Object.keys(categoriesPoi).forEach((category) => {
                temp.push({
                    id: categoriesPoi[category].id,
                    category: categoriesPoi[category].title,
                    status: false,
                });
            });
            setNewCategoriesPointsOfInterest(temp);
            dispatch(listActions.updatefilterCategoryPoi(temp));
        }

        dispatch(boundsActions.updateAllPointsOfInterest());
        dispatch(boundsActions.updateAllExperiences());
    };

    const filterByEndpoint = () => {
        if (searchValue) dispatch(listActions.updateKeywordFilter(searchValue));
        setToggleClearFilter(true);
        const urlExp = `${process.env.REACT_APP_API_URL}/web/experiences`;
        const urlPoi = `${process.env.REACT_APP_API_URL}/web/points-of-interest`;

        const bodyExp = {};
        const bodyPoi = {};

        let tagIds = [];
        let categoryExpIds = [];
        let categoryPoiIds = [];

        if (keywordFilterRedux !== null) {
            bodyExp.keyword = keywordFilterRedux;
            bodyPoi.keyword = keywordFilterRedux;
        }

        newTags.forEach((category) => {
            if (category.status === true) tagIds.push(category.id);
        });

        newCategoriesExperiences.forEach((category) => {
            if (category.status === true) categoryExpIds.push(category.id);
        });
        newCategoriesPointsOfInterest.forEach((category) => {
            if (category.status === true) categoryPoiIds.push(category.id);
        });

        if (tagIds.length !== 0) {
            bodyExp.tag_ids = tagIds;
            bodyPoi.tag_ids = tagIds;
        }

        if (categoryExpIds.length !== 0) {
            bodyExp.experience_category_ids = categoryExpIds;
        }

        if (categoryPoiIds.length !== 0) {
            bodyPoi.category_ids = categoryPoiIds;
        }

        axios({
            method: "get",
            url: urlExp,
            params: bodyExp,
        })
            .then((response) => {
                // console.log("experience", response.data.data);
                dispatch(boundsActions.getAllExperiences(response.data.data));
                dispatch(boundsActions.updateCenterMapExp());
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.message);
                }
            });

        axios({
            method: "get",
            url: urlPoi,
            params: bodyPoi,
        })
            .then((response) => {
                // console.log("point of interest", response.data.data);
                dispatch(boundsActions.getAllPointsOfInterest(response.data.data));
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response.status);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log(error.message);
                }
            });
    };

    const handleInputChange = (event) => {
        setSearchValue(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            dispatch(listActions.updateKeywordFilter(searchValue));
        }
    };

    return (
        <>
            <div className="flex">
                {toggleClearFilter && (
                    <div
                        className="space-x-2 show-hidden-sb shsb_btn shsb_btn_act"
                        onClick={() => {
                            closeModal();
                            cleanFilter();
                        }}>
                        <i className="gmui gmui-filter-none"></i>
                        <span className="!hidden lg:!inline">Curățare</span>
                    </div>
                )}

                <div className="space-x-2 show-hidden-sb shsb_btn shsb_btn_act" onClick={openModal}>
                    <i className="gmui gmui-filter"></i>
                    <span className="!hidden lg:!inline">Filtre</span>
                </div>
            </div>

            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-40" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0">
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95">
                                <Dialog.Panel className="relative w-full max-w-md p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <div className="absolute top-0 right-0 p-4">
                                        <button
                                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                                            onClick={closeModal}
                                            aria-label="Închide">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="w-6 h-6"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor">
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M6 18L18 6M6 6l12 12"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                                        Filtrare căutare
                                    </Dialog.Title>
                                    <div className="my-2">
                                        <h2 className="m-0 mb-2 font-medium">Cuvinte cheie</h2>
                                        <div className="flex flex-col w-full bg-white rounded-md md:flex-row">
                                            <input
                                                className="px-4 py-2.5 border shadow-none outline-none sm:rounded-l-md rounded-t-md sm:my-0 border-slate-400 sm:border-b-transparent "
                                                type="text"
                                                placeholder="Ce cauți?"
                                                value={searchValue}
                                                onChange={handleInputChange}
                                                onKeyPress={handleKeyPress}
                                            />
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <div>
                                            <h2 className="m-0 font-medium">Taguri</h2>
                                            <div className="flex-wrap space-y-2">
                                                {newTags.map((tag, index) => {
                                                    return (
                                                        <Button
                                                            key={index}
                                                            onClick={(_) => {
                                                                toggleStatusTags(tag.tag);
                                                            }}
                                                            className={classNames("!mr-1 capitalize p-button-secondary p-button-sm", {
                                                                "p-button-outlined": !tag.status,
                                                                "p-button-raised": tag.status,
                                                            })}>
                                                            {tag.tag}
                                                        </Button>
                                                    );
                                                })}
                                            </div>
                                        </div>

                                        {togglePOIEXP ? (
                                            <div className="mt-4">
                                                <h2 className="m-0 font-medium">Categorie experiente</h2>
                                                <div className="flex-wrap space-y-2">
                                                    {newCategoriesExperiences.map((category, index) => {
                                                        return (
                                                            <Button
                                                                key={index}
                                                                onClick={(_) => {
                                                                    toggleStatusCategories(
                                                                        category.category,
                                                                        newCategoriesExperiences,
                                                                        "experience"
                                                                    );
                                                                }}
                                                                className={classNames("!mr-1 capitalize p-button-secondary p-button-sm", {
                                                                    "p-button-outlined": !category.status,
                                                                    "p-button-raised": category.status,
                                                                })}>
                                                                {category.category}
                                                            </Button>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="mt-2">
                                                <h2 className="m-0 font-medium">Categorie puncte de interes</h2>
                                                <div className="flex-wrap space-y-2">
                                                    {newCategoriesPointsOfInterest.map((category, index) => {
                                                        return (
                                                            <Button
                                                                key={index}
                                                                onClick={(_) => {
                                                                    toggleStatusCategories(
                                                                        category.category,
                                                                        newCategoriesPointsOfInterest,
                                                                        "pointOfInterest"
                                                                    );
                                                                }}
                                                                className={classNames("!mr-1 capitalize p-button-secondary p-button-sm", {
                                                                    "p-button-outlined": !category.status,
                                                                    "p-button-raised": category.status,
                                                                })}>
                                                                {category.category}
                                                            </Button>
                                                        );
                                                    })}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-4">
                                        <h2 className="m-0 mb-2 font-medium">Stații</h2>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => dispatch(boundsActions.updateToggleStations())}>
                                            {toggleStationsRedux ? (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <i className="gmui gmui-sensor-none"></i>
                                                    <span className="!hidden sm:!inline">Ascunde</span>
                                                </div>
                                            ) : (
                                                <div className="flex items-center justify-center space-x-2">
                                                    <i className="gmui gmui-sensor"></i>
                                                    <span className="!hidden sm:!inline">Arată</span>
                                                </div>
                                            )}
                                        </button>
                                    </div>

                                    <div className="mt-4 space-x-2">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal();
                                                filterByEndpoint();
                                                dispatch(listActions.updateFilterTags(newTags));
                                                dispatch(listActions.updatefilterCategoryExp(newCategoriesExperiences));
                                                dispatch(listActions.updatefilterCategoryPoi(newCategoriesPointsOfInterest));
                                            }}>
                                            Filtrează
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => {
                                                closeModal();
                                                cleanFilter();
                                            }}>
                                            Anulare filtre
                                        </button>
                                        <button
                                            type="button"
                                            className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={closeModal}>
                                            Închide
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}

export default SearchFilters;
